import React from 'react';
import { Button } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import "./ReviewCard.css"

interface ReviewCardProps {
    photos: string[];
    rating: number;
    text: string;
    onAccept: () => void;
    onReject: () => void;
}

const ReviewCard: React.FC<ReviewCardProps> = ({
  rating,
  text,
  photos,
  onAccept,
  onReject,
}) => {
  const imageGalleryItems = photos.map((url) => {
    return {
      original: url,
      thumbnail: url,
    };
  });
  return (
    <div className="review-card-container">
      <ImageGallery items={imageGalleryItems} />
      <h5> Rating : {rating}</h5>
      <p className="review-text">{text}</p>
      <div className="actions">
        <Button variant="success" onClick={onAccept}>Accept</Button>
        <Button variant="danger" onClick={onReject}>Reject</Button>
      </div>
    </div>
  );
};

export default ReviewCard;
