import React, { useState, useEffect } from 'react';
import "./Users.css";
import { Container } from "react-bootstrap";


const Users: React.FC = () => {

    useEffect(() => {
        // execute api calls
    }, []);

    return (
        <Container className="main-container"> 
         <h1>Users</h1>
        </Container>
    );
}

export default Users;
