import { useEffect, useState } from "react";
import "./Trails.css";
import { Container } from "react-bootstrap";
import { apiClient } from "../../../Utilities/apiClient";
import Pagination from 'react-bootstrap/Pagination';
import TrailCard from "../../Reusables/TrailCard/TrailCard";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";

const Trails: React.FC = () => {

    const currentFilters = useSelector((state: RootState) => state.trailFilters);
    const [trails, setTrails] = useState<any>([]);
    const [totalTrails, setTotalTrails] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const pageSize = 9;
    const changeStatus = async (id: number) => {
        const trail = trails.find((trail: any) => trail.id === id);
        if(trail.status === 'active') {
            const response = await apiClient.put('/trails/deactivate', { id });
            if(response.status === 200) {
                trail.status = 'inactive';
                setTrails([...trails]);
            }
        }
        else {
            const response = await apiClient.put('/trails/activate', { id });
            if(response.status === 200) {
                trail.status = 'active';
                setTrails([...trails]);
            }
        }
        
    } 

    const handlePageChange = (newPage: number) => {
        const validNewPage = Math.min(Math.max(newPage, 1), totalPages);
        setCurrentPage(validNewPage);
      };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiClient.get('/trails?page=' + currentPage + '&name=' + currentFilters.name + '&pageSize=' + pageSize + '&status=' + currentFilters.statusFilter + '&sortBy=' + currentFilters.sortBy);
                setTrails(response.data.trails);
                if(totalTrails !== response.data.count)
                {
                    setTotalTrails(response.data.count);
                    const totalPages = Math.ceil(response.data.count / pageSize);
                    setTotalPages(totalPages);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [currentFilters, currentPage, totalTrails]);
    
    const renderTrails = () => {
        return (
            <div className="trail-cards">
                {trails.map((trail: any) => (
                    <TrailCard
                        key={trail.id}
                        id={trail.id}
                        rating={trail.rating}
                        name={trail.name}
                        changeStatus={() => changeStatus(trail.id)}
                        photos={trail.photos}       
                        distance={trail.distance}
                        status={trail.status}
                        difficulty={trail.difficulty}             
                    />
                ))}
            </div>
        );
    }
    
    return (
        <Container className="main-container">
            <div>
                <div className="trail-headers">
                    <h3>Trails</h3>
                </div>
                {renderTrails()}
                <div className="paginetad-trails">
                    <Pagination>
                        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

                        {[...Array(totalPages)].map((_, index) => (
                            <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => handlePageChange(index + 1)}
                            >
                            {index + 1}
                            </Pagination.Item>
                        ))}

                        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                    </Pagination>
                </div>
            </div>        
        </Container>
    );
}

export default Trails;
