import React, { ChangeEvent, useState } from 'react';

interface PhotoUploadProps {
    onPhotosChange: (files: File[]) => void; 
}

const MultiPhotoUpload: React.FC<PhotoUploadProps> = ({ onPhotosChange }) => {
    const [files, setFiles] = useState<File[]>([]);
    const [previews, setPreviews] = useState<string[]>([]);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newFiles = event.target.files ? Array.from(event.target.files) : [];
        setFiles(newFiles);
        onPhotosChange(newFiles);  
        updatePreviews(newFiles);
    };

    const updatePreviews = (newFiles: File[]) => {
        const newPreviews = newFiles.map(file => {
            return URL.createObjectURL(file);
        });
        setPreviews(newPreviews);
    };

    return (
        <div>
            <input
                type="file"
                multiple
                onChange={handleFileChange}
            />
        </div>
    );
};

export default MultiPhotoUpload;
