import React from 'react';
import { Form } from "react-bootstrap";

// Define a TypeScript interface for the props
interface DynamicFormLabelProps {
  text: string;
  lang: 'en' | 'ar';  // Assuming 'lang' can only be 'en' or 'ar'
}

const DynamicFormLabel: React.FC<DynamicFormLabelProps> = ({ text, lang }) => {
  return (
    <Form.Label style={lang === "en" ? { float: "left" } : { float: "right" }}>
      {text}
    </Form.Label>
  );
}

export default DynamicFormLabel;
