import React, { useState } from "react";
import logo from "../../../Assets/icon.png";
import "./Login.css";
import { DynamicFormLabel } from "../../Reusables";
import * as validator from "../../../Utilities/Validators";
import * as _ from "lodash";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../../store/slices/userSlice";
import { apiClient } from "../../../Utilities/apiClient";
import { setTokens } from "../../../store/slices/authSlice";

interface Errors {
  email?: string;
  password?: string;
}

const Login: React.FC = () => {
  const lang = "en";
  const dispatch = useDispatch();


  const [show, setShow] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState<Errors>({});

  const hasError = (field: keyof Errors): string | undefined => {
    return errors[field];
  };

  const submit = async () => {
    setShow(true);
    let errorsObj: Errors = {};
    if (validator.validateEmail(email).status === 0) {
      errorsObj.email = validator.validateEmail(email).error;
    }
    if (validator.validateLogInPassword(password).status === 0) {
      errorsObj.password = validator.validateLogInPassword(password).error;
    }
    if (!_.isEmpty(errorsObj)) {
      setErrors(errorsObj);
      setShow(false);
      return;
    }

    const requestBody = {
      identifier: email.toLowerCase(),
      password,
    };
    const response = await apiClient.post('/auth/login', requestBody);
    dispatch(setCurrentUser(response.data.user));
    dispatch(setTokens(response.data.tokens));
    setShow(false);
  };

  return (
    <div className="Body" style={{ backgroundSize: "50px", direction: lang === 'en' ? 'ltr' : 'rtl' }}>
      <div className="log-in-main row">
        <div className="col-md-7">
          <div className="intro">
            <img src={logo} width={350} alt="logo" />
          </div>
        </div>
        <div className="col-md-4">
          <Form className="log-in-form form-sign-up-labels">
            <Form.Group className="mb-3" controlId="formEmail">
              <DynamicFormLabel lang={lang} text={"Email"} />
              <Form.Control
                type="email"
                placeholder="e@mail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={hasError("email") ? "error" : ""}
              />
              {hasError("email") && (
                <Form.Text className="text-muted err-message">
                  {errors.email}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPassword">
              <DynamicFormLabel lang={lang} text={"Password"} />
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={hasError("password") ? "error" : ""}
              />
              {hasError("password") && (
                <Form.Text className="text-muted err-message">
                  {errors.password}
                </Form.Text>
              )}
            </Form.Group>
            <Button variant="primary" onClick={submit} className="log-in-submit log-in-button">
              Log In
            </Button>
          </Form>
        </div>
      </div>
      <Modal show={show} backdrop="static" keyboard={false}>
        <Modal.Body className="modal-body-volunteer">
          Please wait while we validate your data
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Login;
