import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-image-gallery/styles/css/image-gallery.css";

import { Main, Login, Users, Trails, Reviews, Trail } from "./Resources/Pages";
import { Header, Sidebar } from "./Resources/Layout";
import { Route, Routes } from "react-router-dom";
import * as _ from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import Waypoints from './Resources/Pages/Waypoints/Waypoints';

function App(): JSX.Element {
  const currentUser = useSelector((state: RootState) => state.user).currentUser;

  const renderLogInRoutes = (): JSX.Element => {
    return (
      <Routes>
        <Route Component={Login} path="*" />
      </Routes>
    );
  };

  const renderLoggedInRoutes = (): JSX.Element => {
    return (
      <Routes>
        <Route Component={Users} path="/users" />
        <Route Component={Trails} path="/trails" />
        <Route Component={Trail} path="/trail/edit/:id" />
        <Route Component={Trail} path="/trail/new" />
        <Route Component={Waypoints} path="/waypoints/:id" /> 
        <Route Component={Reviews} path="/reviews" />
        <Route Component={Main} path="/" />
      </Routes>
    );
  };

  return (
    <div className="root-view">
      <div className="app">
        <Header />
        {_.isEmpty(currentUser) ? null : <Sidebar />}
        <div className="app-content">
          {_.isEmpty(currentUser) ? renderLogInRoutes() : renderLoggedInRoutes()}
        </div>
      </div>
    </div>
  );
}

export default App;
