export interface SingleTrail extends Trails {
    [key: string]: any; // Add an index signature to allow indexing with a string
    isRecommended: boolean;
    description: string;
    isTwoWay: boolean;
    trailPath: trailPath;
    startingPoint?: startingPoint;
    recommendedGear?: string;
    notes?: string;
  }

  interface startingPoint {
    coordinates: [number, number];
    type: string
  }
  
  interface trailPath {
    type: string;
    coordinates: [number, number][];
  }

  export interface TimeInterval {
    hours: number;
    minutes: number;
  }

  export interface Trails {
    createdAt: Date;
    cityId: number;
    name: string;
    id: number;
    status: TrailStatus;
    distance: number;
    difficulty: TrailDifficulty;
    time: TimeInterval;
    photos: string[];
    rating: number;
  }


export interface Waypoint {
  name: string;
  id: number;
  location: number[];
  description: string;
  photos: string;
  createdat: Date;
};

  export enum TrailStatus {
    Active = 'active',
    Deleted = 'deleted',
    Pending = 'pending'
}

export enum TrailDifficulty {
    Easy = 'Easy',
    Medium = 'Medium',
    Challenging = 'Challenging',
    Expert = 'Expert'
}
