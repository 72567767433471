import * as _ from "lodash";

interface ValidationResult {
  status: number;
  error?: string;
  data?: any;
}

export const validateName = (name: string): ValidationResult => {
  if (name && name.length > 0) {
    return {
      status: 1,
    };
  }
  return {
    status: 0,
    error: "Name is empty",
  };
};

export const validateEmail = (email: string): ValidationResult => {
  if (email && email.length > 0) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
      return {
        status: 1,
      };
    }
    return {
      status: 0,
      error: "Email is invalid",
    };
  }
  return {
    status: 0,
    error: "Email is empty",
  };
};

export const validatePhoneNumber = (number: string): ValidationResult => {
  if (number && number.length > 0) {
    if (number.length === 11) {
      const re = new RegExp("^[0-9]+$");
      if (re.test(String(number))) {
        return {
          status: 1,
        };
      }
      return {
        status: 0,
        error: "A phone number can only consist of digits",
      };
    }
    return {
      status: 0,
      error: "Phone number length is invalid, a valid phone number is 11 digits",
    };
  }
  return {
    status: 0,
    error: "Phone Number is empty",
  };
};

export const validatePassword = (password: string): ValidationResult => {
  if (password && password.length > 0) {
    if (password.length >= 8) {
      const re = new RegExp('^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\\d)(?=.*[!#$%&? "]).*$');
      if (re.test(String(password))) {
        return {
          status: 1,
        };
      }
      return {
        status: 0,
        error: "Password invalid, password has to have a special character, number, characters",
      };
    }
    return {
      status: 0,
      error: "Password has to be 8 characters at least",
    };
  }
  return {
    status: 0,
    error: "Password is empty",
  };
};

export const validateFile = (file: { name: string }): ValidationResult => {
  if (!_.isEmpty(file.name)) {
    return {
      status: 1,
    };
  }
  return {
    status: 0,
    error: "You did not upload a file",
  };
};



export const validateLogInPassword = (password: string): ValidationResult => {
  if (password && password.length > 0) {
    return {
      status: 1,
    };
  }
  return {
    status: 0,
    error: "Password is empty",
  };
};
